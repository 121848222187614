import { defineStore } from 'pinia';
import { ThemeType } from "~/types/ThemeType";
import { ThemeColorEnum } from "~/types/enum/ThemeColorEnum";

export const useThemeStore = defineStore({
    id: 'theme',
    state: (): ThemeType => ({
        themeColor: ThemeColorEnum.Dark,
    }),
    getters: {},
    actions: {
        fetchThemeSettings() {
            if (localStorage.getItem('themeColor')) {
                this.themeColor = localStorage.getItem('themeColor');
            }
        },

        toggleThemeColor() {
            this.themeColor = this.themeColor === ThemeColorEnum.Dark ? ThemeColorEnum.Light : ThemeColorEnum.Dark;
            localStorage.setItem('themeColor', this.themeColor);
        },
    },
});
