import { defineStore } from 'pinia';
import { useNuxtApp } from "#app";
import { MenuType } from "~/types/MenuType";

export const useMenuStore = defineStore({
    id: 'menu',
    state: (): MenuType => ({
        menuVisible: false,
    }),
    getters: {},
    actions: {
        setMenuVisibility(visible: boolean) {
            this.menuVisible = visible;
        },
    },
});
