<script setup lang="ts">
import {computed} from "vue";
import {useMenuStore} from "~/stores/menu";

const menuStore = useMenuStore();
const menuVisible = computed(() => menuStore.menuVisible)

const toggleMenu = () => {
    menuStore.setMenuVisibility(!menuVisible.value);
}
</script>

<template>
    <header class="container flex justify-between py-10 space-x-4">
        <Logo/>

        <div class="flex items-center space-x-12">
            <ThemeSwitcher class="hidden md:block"/>

            <button type="button" class="button button-function" @click="toggleMenu">
                toggleMenu<span class="text-lightula-blue dark:text-darkula-blue">()</span>
            </button>
        </div>

        <SiteMenu :visible="menuVisible"/>
    </header>
</template>