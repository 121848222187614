<script setup lang="ts">
import { useThemeStore } from "~/stores/theme";
import { computed, onMounted } from "vue";

const themeStore = useThemeStore();
const themeColor = computed(() => themeStore.themeColor);

const switchTheme = () => {
    themeStore.toggleThemeColor();
}

onMounted(() => {
    themeStore.fetchThemeSettings();
});
</script>

<template>
    <p class="code-block">
        theme = THEMES.
        <button @click="switchTheme()" class="button button-code" type="button">{{ themeColor }}</button>
    </p>
</template>